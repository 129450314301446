@import "../variables.scss";
@import "../typography.scss";

.container {
    margin-top: 8rem;
}

.bgImage {
    /* Add the blur effect */
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.subtitle {
    font-size: $font-sizes-14;
    line-height: 22px;
    font-family: "GeneralSans-Regular";
    color: $custom-gray;
}

.title {
    font-size: $font-sizes-40;
}

.infoText {
    font-size: $font-sizes-12;
    line-height: 18px;
    font-family: "GeneralSans-Regular";
    color: $custom-gray;

    &Links {
        font-family: "GeneralSans-Semibold";
        color: $primary-color;
        cursor: pointer;
    }
}

.checkboxContainer {
    color: $custom-gray;
    label {
        font-size: $font-sizes-12;
        line-height: 18px;
        font-family: "GeneralSans-Regular";
    }
}

.customCheckboxChecked {
    &:after {
        color: $primary-color;
    }

    &:checked {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }

    &:before {
        background-color: $primary-color !important;
        box-shadow: 0px 0px 0px 13px $primary-color !important;
        border-color: $primary-color !important;
        &:checked {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
        }
        &:focus {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
        }
    }
}

