@import "./variables.scss";

.textPrimary {
    color: $primary-color;
}

.textBlack {
    color: $custom-black;
}
.textBlackThree {
    color: $custom-black-three;
}

.textWhite {
    color: $custom-white;
}

.textWhiteLight {
    color: $custom-white-two;
}

.textGreen {
    color: $success-color;
}

.textGrayLight {
    color: $custom-gray-two;
}
.textGray {
    color: $custom-gray;
}

.textOrange {
    color: $secondary-color-four;
}