@import "../../variables.scss";

@mixin buttonCarousel($border, $hover-color, $cursor-value) {
    background-color: transparent;
    border: $border;
    box-shadow: none;

    path {
        fill: $custom-gray;
    }

    &:hover {
        background-color: transparent;
        border: $border;
        box-shadow: none;
        cursor: $cursor-value;

        path {
            fill: $hover-color;
        }
    }
}

.buttonActive {
    @include buttonCarousel(2px solid $primary-color, $primary-color, pointer);
}

.buttonInactive {
    @include buttonCarousel(none, $custom-gray, default);
}
