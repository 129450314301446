/**
 * @license
 *
 * Font Family: General Sans
 * Designed by: Frode Helland
 * URL: https://www.fontshare.com/fonts/general-sans
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * General Sans Regular
 * General Sans Semibold
 *
*/

@font-face {
    font-family: "GeneralSans-Regular";
    src: url("../../fonts/GeneralSans-Regular.woff2") format("woff2"),
        url("../../fonts/GeneralSans-Regular.woff") format("woff"),
        url("../../fonts/GeneralSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "GeneralSans-Semibold";
    src: url("../../fonts/GeneralSans-Semibold.woff2") format("woff2"),
        url("../../fonts/GeneralSans-Semibold.woff") format("woff"),
        url("../../fonts/GeneralSans-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}
