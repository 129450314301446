@import "../variables.scss";
@import "../breakpoints.scss";
@import "../typography.scss";


.contentBody {
  @include respond-to($upToMD) {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

.modalTitle {
  font-family: "CabinetGrotesk-Extrabold";
  font-style: normal;
  font-weight: 700;
  font-size: $font-sizes-32;
  line-height: 40px;
}

.closeButton {
  border-radius: 50%;
  border: 2px solid $custom-gray-three;
  opacity: 1;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.image,
.noElement,
.imageContainer{
  min-height: 180px;
}

.image{
  width: 180px;

  @include respond-to($upToSM) {
    width: 100%;
  }
  border-radius: 16px;
  object-fit: cover;
}

.noElement {
  font-size: $font-sizes-16;
  font-family: "GeneralSans-Regular";
}
.cardStarIcon {
  padding-bottom: 0.2rem;
  svg {
    transform: scale(0.7);
    path {
      fill: $secondary-color-three;
    }
  }
}

.starText {
  font-family: "GeneralSans-Semibold";
  font-size: $font-sizes-14;
}

.cardClockIcon {
  padding-bottom: 0.2rem;
  svg {
    transform: scale(0.7);
  }
}

.flagContainer {
  svg {
    transform: scale(0.7);
  }
}

.cardTitle {
  font-size: $font-sizes-24;
  font-family: "GeneralSans-semibold";
}

.cardSecondaryText {
  font-size: $font-sizes-12;
  color: $custom-black-fuor;
}

.cardActivityTitle {
  font-size: $font-sizes-10;
  color: $custom-black-fuor;
  margin-bottom: 0.5rem;
}

.cardSecondaryTextBold {
  font-size: $font-sizes-12;
  color: $custom-black-fuor;
  font-weight: 600;
  color: $custom-black;
}

.textPrice {
  color: $success-color;
  font-family: "GeneralSans-Semibold";
  font-size: $font-sizes-16;
}