@import "./breakpoints.scss";
@import "./variables.scss";

$prefix: "PI";

.#{$prefix} {
    &border {
        border: $border;
    }

    &Color {
        &Gray {
            color: $custom-gray !important;
        }
        &GrayTwo {
            color: $custom-gray-two !important;
        }
        &GrayThree {
            color: $custom-gray-three !important;
        }
    }

    &font {
        &Regular {
            font-family: "GeneralSans-Regular" !important;
        }
        &SemiBold {
            font-family: "GeneralSans-SemiBold" !important;
        }
    }

    &h- {
        &60 {
            height: 60% !important;
        }
        &80 {
            height: 80% !important;
        }
        &90 {
            height: 90% !important;
        }
    }

    &MainContainer {
        margin-top: 120px;

        padding: 1rem 1rem;

        @include respond-to($fromXL) {
            padding: 1rem 3rem;
        }

        @include respond-to($fromXXL) {
            padding: 1rem 5rem;
        }
    }

    &MaxW {
        &1920 {
            max-width: 1920px;
        }

        &R {
            &70 {
                max-width: 70rem;
            }
        }
    }

    &SectionDividerSM {
        margin: 0;
        border-top: $border;
    }

    &SectionDivider {
        margin: 2rem 0;
        border-top: $border;
    }
    &SectionDividerVertical {
        display: inline-block;
        width: 2px;
        height: 100%;
        background-color: $custom-gray-three;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    &CustomCheckbox {
        border-color: $custom-gray-three !important;

        &:checked {
            border-color: $custom-gray-three !important;
        }

        &:before {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
            &:checked {
                background-color: $primary-color !important;
                box-shadow: 0px 0px 0px 13px $primary-color !important;
                border-color: $primary-color !important;
            }
            &:focus {
                background-color: $primary-color !important;
                box-shadow: 0px 0px 0px 13px $primary-color !important;
                border-color: $primary-color !important;
            }
        }
    }

    &CustomInput {
        padding: 0.5rem;
        border: $border !important;
        border-radius: 12px !important;
        box-sizing: border-box;
        color: $custom-gray !important;
        font-family: "GeneralSans-Regular" !important;
        &:focus,
        &:focus-visible {
            border-color: $primary-color !important;
            outline: none !important;
        }
    }
    &CustomTextArea {
        border: $border !important;
        border-radius: 12px !important;
        &:focus,
        &:focus-visible {
            border-color: $custom-gray-two !important;
            outline: none !important;
        }
        &.form-control:focus ~ .form-label {
            display: none !important;
        }
        &.form-control:active ~ .form-label {
            display: none !important;
        }
    }
}
