@import "../variables.scss";
@import "../typography.scss";
@import "../buttons.scss";
@import "../breakpoints.scss";

.cardBodySmall,
.cardBody {
    border-radius: 25px;
    @include respond-to($upToXS) {
        aspect-ratio: 1/1.3;
    }
    aspect-ratio: 1/1.55;
    min-width: 100%;
    min-height: 100%;
    box-shadow: none;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
    &:hover {
        box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07),
            0 10px 20px -2px rgba(0, 0, 0, 0.04);

        div {
            mask {
                opacity: 1 !important;
            }
        }
    }
}

.startingPriceStyle {
    color: #83899a;
    line-height: 18px;
    font-size: 13px;
    font-family: "CabinetGrotesk-Bold";
}

.cardBodySmall {
    flex: auto;
    max-width: 60%;
    @include respond-to($upToXS) {
        height: 90%;
    }

    img {
        width: 100%;
    }
}

.imageContainer {
    @include respond-to($upToXS) {
        height: 200px;
    }
}

.ripple {
    border-radius: 25px 25px 0px 0px !important;
    height: 58%;

    :hover > & > .customMask {
        opacity: 1 !important;
    }

    img {
        height: 100%;
    }

    .tag {
        z-index: 3;
        position: absolute;
        top: 5%;
        left: 5%;
        background-color: $primary-color;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        padding: 0.33rem;
        color: $custom-white-two;
        text-transform: uppercase;
    }

    &:hover {
        .tag {
            display: none;
        }
    }
}

.ratingTag {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    height: 30px;

    /* Neutrals/8 */
    background: $custom-white;
    border-radius: 24px;

    /* Inside auto layout */
    flex: 0 1 auto;
    order: 0;
}

.colorSvg {
    path {
        fill: $primary-color;
    }
}

.textPriceSmall,
.textTitleSmall,
.textPrice,
.textTitle {
    font-family: "GeneralSans-Semibold";
}

.caption,
.tag {
    font-family: "GeneralSans-Regular";
}

.caption {
    font-size: $font-sizes-14;
}

.tag {
    font-size: $font-sizes-10;
}

.textTitle {
    font-size: $font-sizes-24;
}

@mixin trim($numLines: null) {
    @if $numLines !=null {
        display: -webkit-box;
        -webkit-line-clamp: $numLines;
        -webkit-box-orient: vertical;
        overflow: hidden;
    } @else {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
    }
}

.textTitleSmall,
.textTitle {
    color: $custom-black !important;
    @include trim(2);
}

.textPrice {
    font-size: $font-sizes-16;
}

.textTitleSmall {
    font-size: $font-sizes-16;
}

.tagSmall {
    font-size: $font-sizes-10;
}

.textButtonSmall,
.textPriceSmall {
    font-size: $font-sizes-14 !important;
}

.captionSmall {
    font-size: $font-sizes-12;
}

.textPriceSmall,
.textPrice {
    color: $success-color;
}

.captionSmall,
.caption {
    color: $custom-gray;

    span {
        color: $custom-black;
        font-family: "GeneralSans-Semibold";
    }
}

.buttonLike {
    background-color: $custom-white;

    path {
        fill: $custom-gray;
    }

    &:hover {
        background-color: $custom-white;

        path {
            fill: $primary-color;
        }
    }
}

.buttonLikePressed {
    background-color: $custom-white;

    path {
        fill: $primary-color;
    }

    &:hover {
        background-color: $custom-white;

        path {
            fill: $custom-gray;
        }
    }
}

.buttonDetails {
    @include buttonLine(
        $custom-white-two,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );
}

.dispShrink,
.dispFlex {
    display: flex;
}

.dispShrink {
    flex: 0 1 auto;
    flex-direction: row;
    min-height: 0;
    justify-content: center;
    align-items: center;
}

@media (min-width: 992px) {
    .cardBody {
        aspect-ratio: 1/1.55;
    }

    .cardBodySmall {
        max-width: calc(90%);
    }
}

@media (min-width: 1200px) {
    .cardBodySmall {
        max-width: calc(80%);
    }
}

@media (min-width: 1400px) {
    .cardBodySmall {
        max-width: calc(80%);
    }
}
