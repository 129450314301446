@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";

.menuSticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    .fixedBanner {
        width: 100vw;
        height: 24px;
        background-color: #55ce97;
        text-align: center;

        &:hover {
            cursor: pointer;
        }

        .label {
            color: #282838;
            font-size: 10px;
            text-transform: uppercase;
            font-family: "GeneralSans-Semibold";
            font-weight: 500;

            @include respond-to($upToSM) {
                font-size: 8px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.bgColor {
    background-color: $primary-color;
    color: $text-color;
}

.svg {
    path {
        fill: $primary-color;
    }
}
.svgWhite {
    path {
        fill: white;
    }
}

.button_highlight {
    background-color: $primary-color;
    border-color: $primary-color;
}

.button {
    border-color: $custom-gray-three;
}

.paragraphStyle {
    color: $custom-gray;
    text-transform: capitalize;
    font-family: "GeneralSans-Semibold";
    font-size: $font-sizes-14;

    &:hover {
        color: $primary-color;
        cursor: pointer;
        path {
            fill: $primary-color;
        }
    }
}
.paragraphStyleWhite {
    color: white;
    text-transform: capitalize;
    font-family: "GeneralSans-Semibold";
    font-size: $font-sizes-14;

    &:hover {
        color: rgba(255, 255, 255, 0.658);
        cursor: pointer;
        path {
            fill: white;
        }
    }
}

.desktop {
    ul {
        margin-top: 0.3rem;
    }
}

.linkText {
    font-size: $font-sizes-14;

    &:target {
        color: $primary-color;
    }

    &[aria-expanded="true"] {
        p {
            color: $primary-color;
        }
    }
}

@media (min-width: 992px) {
    .vertical_divider_left {
        border-left: 1px solid $custom-gray-three;
    }

    .vertical_divider_right {
        border-right: 1px solid $custom-gray-three;
    }

    .mobile {
        display: none;
    }

    .desktop {
        display: flex;
    }

    .subMenu {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .mobile {
        display: flex;

        i {
            .top_icons {
                color: $primary-color;
                border-color: $primary-color;
            }
        }
    }

    .desktop {
        display: none;
    }

    .lateralMenu {
        display: none !important;
    }

    .subMenu {
        display: flex;
    }
}
