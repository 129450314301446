@import "../variables.scss";
@import "../typography.scss";

.error {
    border-color: $error-color !important;
}
.success {
    border-color: $success-color !important;
}

.datePickerButton {
    background-color: transparent;
    width: 100%;
    min-height: 45px;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-sizes-16;
    padding: 1rem;
    cursor: pointer;

    padding: 0.5rem;
    border-radius: 12px !important;
    box-sizing: border-box;
    color: $custom-gray !important;
    font-family: "GeneralSans-Regular" !important;

    border: $border;

    &:focus,
    &:focus-visible {
        outline: none !important;
    }

    &:focus,
    &:focus-visible {
        border-color: $custom-gray-two !important;
        outline: none !important;
    }

    svg {
        path {
            fill: $primary-color;
            transform: scale(0.7);
        }
    }

    &:hover {
        box-shadow: none;

        background-color: $custom-white;
        color: $text-color;
    }
    &:focus {
        background-color: $custom-white;
        color: $text-color;
        box-shadow: none;
    }
}
