@import "../variables.scss";
@import "../typography.scss";

.dropdownContainer {
    padding: 0.3rem 5rem;
    z-index: 1000;
}

.dropdownMiniContent {
    border-radius: 15px;
    margin-top: 2rem;
    left: -15%;
    width: max-content;
}

.dropdownMiniText {
    color: $custom-gray;
    text-transform: capitalize;
    font-family: "GeneralSans-Semibold";
    font-size: $font-sizes-14;

    &:hover {
        color: $primary-color;
        cursor: pointer;
        path {
            fill: $primary-color;
        }
    }
}

.dropdownContent {
    border-radius: 25px;
    height: 500px;
    box-shadow: 0px 40px 32px -24px #0f0f0f65;
}

.dropdownColumnRight {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: $custom-white;
}
.dropdownColumnLeft {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    overflow: hidden;
    object-fit: cover;
}

.dropdownInnerColumn {
    padding: 2rem 0;
    height: 100%;
    max-height: 500px;
}

.dropdownColumnContent {
    padding: 0 2rem;
    height: fit-content;
}

.columnParagraph {
    font-family: "GeneralSans-Regular";
}

.dropdownColumnOptions,
.dropdownColumnCenterButton,
.dropdownColumnLeftButton {
    font-family: "GeneralSans-Semibold";
}

.columnParagraph,
.dropdownColumnCenterButton,
.dropdownColumnLeftButton {
    font-size: $font-sizes-14;
}

.dropdownColumnOptions {
    font-size: $font-sizes-16;
}

.dropdownColumnLeftButton {
    background-color: $primary-color;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    svg {
        path {
            fill: $text-color;
            transform: scale(0.7);
        }
    }
    &:hover,
    &:focus {
        background-color: $custom-white;
        color: $primary-color;
        svg {
            path {
                fill: $primary-color;
            }
        }
    }
}
.dropdownColumnCenterButton {
    background-color: #fbfbfb;
    color: $primary-color;
    border: 2px solid $custom-gray-three;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    width: 100%;

    &:hover {
        background-color: $primary-color;
        color: $custom-white;
        border: 2px solid $primary-color;
    }
    &:focus {
        background-color: $primary-color;
        color: $custom-white;
        border: 2px solid $primary-color;
    }
}

.dropdownColumnCenterBackButton {
    background-color: #fbfbfb;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $custom-gray-three;
    path {
        fill: $primary-color;
    }

    &:hover {
        background-color: $primary-color;
        color: $custom-white;
        border: 2px solid $primary-color;
        path {
            fill: $custom-white;
        }
    }
    &:focus {
        background-color: $primary-color;
        color: $custom-white;
        border: 2px solid $primary-color;
        path {
            fill: $custom-white;
        }
    }
}

.dropdownColumnCenterButtonContainer {
    min-height: 41.19px;
}

.dropdownColumnTitle {
    font-family: "GeneralSans-Semibold";
    font-size: $font-sizes-24;
    color: $primary-color;
}
.dropdownColumnOptions {
    color: $custom-black-fuor;
    padding-top: 1rem;
    border-bottom: 1px solid $custom-gray-three;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }

    svg {
        border: 1px solid $custom-white;
        border-radius: 50%;
        background-color: $custom-white;
    }
}

.columnParagraph {
    color: $custom-black-fuor;
}

.titleSelected {
    color: $primary-color;
}

.dropDownCarouselCard {
    max-height: 21rem;
    &Body {
        min-height: 7.5rem;
    }
}

@media (max-width: 992px) {
    .dropdownContainer {
        display: none;
    }
}
@media (max-width: 1400px) {
    .dropdownContainer {
        padding: 0.3rem 3rem;
    }
}

@media (max-width: 1200px) {
    .dropdownContainer {
        padding: 0.3rem 1rem;
    }
}
