@import "../variables";

.container {
    background-color: $custom-white;
    width: fit-content;
    padding: 0.5rem 1rem;
    top: 5%;
    left: 0;
    right: 0;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 9999999999;
    border: 0.5px solid $custom-gray-three;
    border-radius: 8px;
    box-shadow: 0px 3px 12px $custom-gray-three;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    font-family: $font-bold;
}

.messageSuccess {
    p {
        color: $success-color;
    }
    svg {
        background-color: $success-color;
        path {
            stroke: $custom-white;
        }
    }
}
.messageError {
    p {
        color: $error-color;
        font-family: $font-bold;
    }
    svg {
        background-color: $error-color;
        border-radius: 50%;
        path {
            fill: $custom-white;
        }
    }
}

.messageInfo {
    p {
        color: $custom-gray;
    }
}

.container svg {
    width: 1.5rem;
    border-radius: 10px;
    margin-right: 0.5rem;
    box-shadow: 0px 1px 12px $custom-gray-three;
}
