@import "../breakpoints.scss";

.fixMenu {
    @include respond-to($upToSM) {
        overflow-y: scroll !important;
    }
    @include respond-to($upToMD) {
        overflow-y: scroll !important;
    }

    &::-webkit-scrollbar {
        @include respond-to($upToSM) {
            display: none;
        }
        @include respond-to($upToMD) {
            display: none;
        }
    }
}
