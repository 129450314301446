@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 250px;
    font-family: "CabinetGrotesk-Extrabold";
    padding-top: 150px;
    padding-bottom: 100px;
    background-image: url("/images/bg-4042.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-to($upToSM) {
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 0px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("/images/bg_404_mobile.svg");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @include respond-to($upToMD) {
        margin-top: 125px;
        padding-left: 80px;
        padding-right: 80px;
    }

    .title,
    .FourOhFour,
    .message {
        font-style: normal;
        font-weight: 700;
        font-family: "CabinetGrotesk-Extrabold";
    }

    .title {
        font-size: 48px;
        letter-spacing: -0.02em;
        margin-bottom: -50px;

        @include respond-to($upToSM) {
            margin-bottom: -30px;
            font-size: 40px;
        }
    }

    .FourOhFour {
        font-size: 240px;
        color: #ed6f6f;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        margin-bottom: -40px;

        @include respond-to($upToSM) {
            font-size: 142px;
            // margin-bottom: 0px;
        }
    }

    .message {
        font-size: 48px;
        line-height: 56px;
        color: #353945;
        width: 30%;
        margin-bottom: 30px;

        @include respond-to($upToSM) {
            width: 100%;
            text-align: center;
            font-size: 40px;
        }
    }

    .buttonArea {
        display: flex;
        gap: 20px;

        @include respond-to($upToSM) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .buttonBack,
        .buttonHome {
            padding: 5px 15px;
            border-radius: 90px;
            color: white;
            font-size: 16px;
        }

        .buttonBack {
            background: #ed6f6f;

            &:hover {
                cursor: pointer;
            }
        }

        .buttonHome {
            background: #282838;
        }
    }
}
