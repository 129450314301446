@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &Content {
        border-radius: 3rem;
    }

    &Dialog {
        max-width: fit-content
    }

    @include respond-to($upToSM) {
        padding: 1rem;
        &Dialog {
            margin: 0 auto;
        }
    }

    @include respond-to($upToMD) {
        padding: 1rem;
    }

    &BtnClose {
        position: absolute;
        background: $text-color;
        box-shadow: $shadow;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        top: -10px;
        right: -10px;
        border: $border;

        @include respond-to($fromMD) {
            right: -40px;
            top: 20px;
            border: none;
        }
    }
}