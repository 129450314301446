@import "../variables.scss";
@import "../typography.scss";

.btn {
    font-family: $font;
    cursor: pointer;
}

.primary {
    @extend .btn;
    background-color: $primary-color;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: $font-sizes-16;
    font-family: "GeneralSans-SemiBold";
    svg {
        path {
            fill: $text-color;
            transform: scale(0.7);
        }
    }
    &:hover {
        background-color: $custom-gray-three;
        border-color: $custom-gray-three;
        color: $primary-color;
        svg {
            path {
                fill: $primary-color;
            }
        }
    }
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
}

.disabled {
    background-color: $custom-gray !important;
    border-color: $custom-gray !important;
    color: $text-color !important;
    cursor: not-allowed !important;
    svg {
        path {
            fill: $text-color !important;
        }
    }
    &:hover {
        cursor: not-allowed !important;
    }
}

.gray {
    @extend .btn;
    background-color: $custom-gray-three;
    border-radius: 50px;
    border: $border;
    color: $custom-black;
    cursor: pointer;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $custom-gray;
        }
    }
}

.transparent {
    @extend .btn;
    background-color: transparent;
    border-radius: 50px;
    border: $border;
    color: $custom-gray;
    text-transform: none;
    cursor: pointer;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $custom-gray;
        }
    }
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
}
.transparentBlack {
    @extend .btn;
    background-color: transparent;
    border-radius: 50px;
    border: $border;
    color: black;
    font-family: "GeneralSans-Semibold";
    text-transform: none;
    cursor: pointer;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $custom-gray !important;
            fill: $text-color !important;
        }
    }
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $primary-color;
                fill: $text-color;
            }
        }
    }
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $primary-color;
                fill: $text-color;
            }
        }
    }
}

.transparentNoHover {
    @extend .btn;
    background-color: transparent;
    border-radius: 50px;
    border: $border;
    color: $custom-gray;
    text-transform: none;
    cursor: pointer;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $custom-gray ;
        }
    }
    &:hover {
        background-color: transparent;
        border: $border;
        color: black;
        svg {
            path {
                fill: black;
            }
        }
    }
    &:focus {
        background-color: transparent;
        border: $border;
        color: black;
        svg {
            path {
                fill: black;
            }
        }
    }
}

.secondary {
    @extend .btn;
    color: $custom-black-fuor;
    border-radius: 50px;
    border: $border;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $custom-black-fuor;
            transform: scale(0.7);
        }
    }
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
}

.black {
    @extend .btn;
    color: $text-color;
    border-radius: 50px;
    background-color: $custom-black;
    border: $border;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $text-color;
            transform: scale(0.7);
        }
    }
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
}

.blue {
    @extend .btn;
    color: $text-color;
    border-radius: 50px;
    background-color: $custom-blue;
    border: $border;
    box-shadow: none;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: $font-sizes-16;
    svg {
        path {
            fill: $text-color;
            transform: scale(0.7);
        }
    }
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-color;
        svg {
            path {
                fill: $text-color;
            }
        }
    }
}
