@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";

.modal {
    @include respond-to($fromMD) {
        max-width: 45rem;
    }
    @include respond-to($fromXL) {
        max-width: 55rem;
    }
    &Logged {
        min-width: 18rem;
        @include respond-to($fromMD) {
            position: absolute;
            top: 52px;
            right: 40px;
            max-width: 20rem;
        }

        @include respond-to($fromLG) {
            position: absolute;
            top: 52px;
            right: 40px;
            max-width: 20rem;
        }
    }

    &BtnClose {
        position: absolute;
        background: $text-color;
        box-shadow: $shadow;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        top: 10px;
        right: 10px;
        border: $border;

        @include respond-to($fromMD) {
            right: -10px;
            top: -10px;
            border: none;
        }
    }
}

.preLogin {
    &Icon {
        svg {
            transform: scale(0.7);
            path {
                transform: none !important;
            }
        }
    }
    &IconArrow {
        width: 1.7rem;
        height: 1.7rem;
        svg {
            background: $primary-color;
            border-radius: 50%;
            path {
                fill: $text-color !important;
            }
        }
    }
}

.loggedText {
    font-family: "GeneralSans-Semibold";
    &Icon {
        svg {
            path {
                fill: $custom-gray !important;
            }
        }

        &:hover {
            svg {
                path {
                    fill: $text-color !important;
                }
            }
        }
    }
}

.subtitle {
    font-size: $font-sizes-16 !important;
    line-height: 22px;
    font-family: "GeneralSans-Regular";
    color: $custom-gray;
}

.title {
    font-size: $font-sizes-36;
}

.infoText {
    font-size: $font-sizes-12;
    line-height: 18px;
    font-family: "GeneralSans-Regular";
    color: $custom-gray;
    &DarkLinks {
        font-family: "GeneralSans-Semibold";
        color: $custom-black;
        cursor: pointer;
        font-size: $font-sizes-14;
    }
    &Links {
        font-family: "GeneralSans-Semibold";
        color: $primary-color;
        cursor: pointer;

        &SM {
            font-family: "GeneralSans-Semibold";
            color: $primary-color;
            cursor: pointer;
            font-size: $font-sizes-12;
        }
    }
}

.checkboxContainer {
    color: $custom-gray;
    label {
        font-size: $font-sizes-12;
        line-height: 18px;
        font-family: "GeneralSans-Regular";
    }
}

.customCheckboxChecked {
    &:after {
        color: $primary-color;
    }

    &:checked {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }

    &:before {
        background-color: $primary-color !important;
        box-shadow: 0px 0px 0px 13px $primary-color !important;
        border-color: $primary-color !important;
        &:checked {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
        }
        &:focus {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
        }
    }
}
