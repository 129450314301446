@import "../variables.scss";
@import "../font-face/general-sans.scss";

.input {
    padding: 0.5rem;
    border-radius: 12px;
    box-sizing: border-box;
    color: $custom-gray;
    font-family: "GeneralSans-Regular";
    border: $border;

    &:focus,
    &:focus-visible {
        outline: none !important;
    }

    &:focus,
    &:focus-visible {
        border-color: $custom-gray-two !important;
        outline: none !important;
    }

    &Default {
        border: $border;
    }

    &Error {
        border-color: $error-color;
    }

    &Success {
        border-color: $success-color;
    }
}

.iconSelect {
    cursor: pointer;
}

.icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: silver;

    &Success {
        svg {
            path {
                stroke: $success-color;
            }
        }
    }

    &Select {
        border: $border;
        border-radius: 50%;
    }
}

.container {
    p {
        font-family: "GeneralSans-Regular";
        margin-bottom: 0.5rem;
        font-size: 12px;
        font-weight: 400;
        color: $custom-gray;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        text-indent: 1px;
        // text-overflow: "";
    }
    select::-ms-expand {
        display: none;
    }
}
