@import "../variables.scss";

.background {
    background-image: url("/images/Home/bg_modal_newsletter.jpg");
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bigText,
.smallText,
.formCheckLabel {
    color: #777e90;
}

.bigText {
    font-size: 16px;
}

.smallText {
    font-size: 12px;
}

.formCheckLabel {
    // font-family: $font;
    font-size: 14px !important;
}
