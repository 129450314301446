@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";

.modal {
    @include respond-to($fromMD) {
        max-width: 45rem;
    }
    @include respond-to($fromXL) {
        max-width: 55rem;
    }

    &Content {
        overflow: visible !important;
    }

    &BtnClose {
        position: absolute;
        background: $text-color;
        box-shadow: $shadow;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        top: 10px;
        right: 10px;
        border: $border;
        z-index: 10000;

        @include respond-to($fromMD) {
            right: -10px;
            top: -10px;
            border: none;
        }
    }
}

.subtitle {
    font-size: $font-sizes-16 !important;
    line-height: 22px;
    font-family: "GeneralSans-Regular";
    color: $custom-gray;
}

.title {
    font-size: $font-sizes-36;
}
