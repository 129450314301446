@import "./variables.scss";
@import "./typography.scss";

//Footer component - upper part

.footerLayout {
    &Container {
        padding: 1.5rem;

        @media (max-width: 993px) {
            padding: 3rem 2rem;
        }
    }
}

.footerLinksMob {
    &Accordion:first-child {
        border-top: 1px solid $custom-black-three;
    }
    &Accordion {
        border-bottom: 1px solid $custom-black-three;
    }
}

.footerCta {
    &Title {
        font-size: $font-sizes-24;
        font-family: "GeneralSans-Semibold";
        color: $custom-gray-two;
    }
}

.footBgColor {
    background-color: $custom-black-two;
    color: $text-color;
}
.footBgColorBgMobile {
    background-color: $custom-black-two;
    color: $text-color;
    background-image: url("/images/keep_in_touch_mobile.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.footBgColorBgDesktop {
    background-color: $custom-black-two;
    color: $text-color;
    background-image: url("/images/support_hero_desktop.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

.accordion {
    pointer-events: none !important;
}

.imageCover {
    object-fit: contain;
}

.footInputContainer,
.footInputContainerBtnCta {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 5vw;
    border: 2px solid $custom-gray;
    border-radius: 3em;
    background-color: $custom-black-two;
}

.footInputCTA,
.footInputCTABtn {
    margin-left: 0.5rem;
    background-color: $custom-black-two;
    color: white;
    border: none;
    outline: none;
    height: 30px;
    transition: height 1s;
    -webkit-transition: height 1s;

    &::placeholder {
        color: $custom-gray;
        opacity: 1;
    }
}

.footInputContainerBtnCta,
.footInputCTABtn {
    background-color: transparent !important;
    color: $custom-black;
}

.footInputButton {
    background-color: $primary-color;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    path {
        fill: $custom-white-two;
    }

    &:hover {
        background-color: $primary-color;
    }
}

.footInputCTA,
.text_content {
    cursor: pointer;
    font-size: $font-sizes-16;
    font-family: "GeneralSans-Semibold";
    color: $custom-gray !important;
}

.kit_title {
    font-family: "Tanker-Regular";
    font-size: $font-sizes-120;
    line-height: 110px;
}

.accordionItem {
    font-family: "GeneralSans-Semibold" !important;
    background-color: $custom-black-two;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px;
    border-color: $custom-black-three !important;

    Button {
        background-color: $custom-black-two !important;
        color: $custom-white !important;

        &:not(.collapsed)::after,
        &::after {
            background-color: $custom-black !important;
            border-radius: 100%;
            background-image: url("../public/custom-icons/icons-2/icons/Arrow Down Simple/Line.svg");
        }
    }
}

@media (max-width: 992px) {
    .max_padding {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .kit_title {
        font-weight: 300;
        font-size: 100px;
    }
}

@media (min-width: 992px) {
    .max_padding {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

@media (min-width: 1200px) {
    .max_padding {
        padding-left: 15rem;
        padding-right: 15rem;
    }
}

@media (max-width: 767px) {
    .accordion {
        pointer-events: all !important;
    }
}

@media (max-width: 992px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .text_title {
        font-size: $font-sizes-16;
    }
}
