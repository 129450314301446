@import "../variables.scss";
@import "../typography.scss";
@import "../utils.scss";

.navBarM {
    height: 100%;
    &Paragraph {
        color: $custom-gray;
        text-transform: capitalize;
        font-family: "GeneralSans-Semibold";
        font-size: $font-sizes-14;

        &:hover {
            color: $primary-color;
            cursor: pointer;
            path {
                fill: $primary-color;
            }
        }
    }

    p,
    div,
    a {
        font-family: "GeneralSans-Regular";
        color: $custom-gray;
    }

    &Link {
        border-bottom: 2px solid $custom-gray-three;

        a {
            color: $custom-gray;
            span {
                font-family: "GeneralSans-Semibold";
                color: inherit;
                font-weight: 600;
                font-size: 1.1rem;
            }

            &Icon2 {
                color: inherit;
                background-color: $custom-white;
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
            }
        }

        &Active {
            border-bottom: 2px solid $primary-color;
            background-color: $primary-color;
            color: $text-color;

            a {
                color: $text-color;

                span {
                    color: $text-color;
                }
                svg {
                    path {
                        fill: $text-color;
                    }
                }
            }
        }
    }

    &Logo {
        @media (max-width: $breakpoint-mobile-sm) {
            img {
                max-width: 6rem;
            }
        }
    }

    &SubMenu {
        margin: 0 auto;
        list-style: none;

        &Selected {
            &Content {
                height: calc(100% - 4rem);
                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
            &Description {
                font-size: 14px;
                @include trim(4);
            }
            &Btn {
                background-color: transparent;
                color: $primary-color;
                border: 2px solid $custom-gray-three;
                box-shadow: none;
                text-transform: none;
                display: flex;
                justify-content: center;
                width: 100%;
                font-size: 1rem;
                font-weight: 600;

                &:hover {
                    background-color: $primary-color;
                    color: $custom-white;
                    border: 2px solid $primary-color;
                }
                &:focus {
                    background-color: $primary-color;
                    color: $custom-white;
                    border: 2px solid $primary-color;
                }
            }
        }
    }

    &Action {
        &Container {
            background-color: $custom-black;
            max-height: 5.4rem;
            svg {
                transform: scale(0.8);
            }
        }

        &Btn {
            border-color: $text-color;
            color: $text-color;
            font-size: $font-sizes-16;

            @media (max-width: $breakpoint-mobile-sm) {
                font-size: $font-sizes-14;
            }

            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }
            svg {
                path {
                    fill: $text-color;
                }
            }
        }
    }

    &BackIcon {
        span:first-child {
            font-size: $font-sizes-14;
        }
        div:nth-child(2) {
            color: $text-color;
            background-color: $primary-color;
            border: 1px solid $text-color;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            text-align: center;
        }
    }
}
