/**
 * @license
 *
 * Font Family: Cabinet Grotesk
 * Designed by: ITF
 * URL: https://www.fontshare.com/fonts/cabinet-grotesk
 * © 2022 Indian Type Foundry
 *
 * Font Style:
 * Cabinet Grotesk Extrabold         
 *
*/

@font-face {
    font-family: "CabinetGrotesk-Extrabold";
    src: url("../../fonts/CabinetGrotesk-Extrabold.woff2") format("woff2"),
        url("../../fonts/CabinetGrotesk-Extrabold.woff") format("woff"),
        url("../../fonts/CabinetGrotesk-Extrabold.ttf") format("truetype");
    font-weight: 800;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "CabinetGrotesk-Bold";
    src: url("../../fonts/CabinetGrotesk-Bold.woff2") format("woff2"),
        url("../../fonts/CabinetGrotesk-Bold.woff") format("woff"),
        url("../../fonts/CabinetGrotesk-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "CabinetGrotesk-Regular";
    src: url("../../fonts/CabinetGrotesk-Regular.woff2") format("woff2"),
        url("../../fonts/CabinetGrotesk-Regular.woff") format("woff"),
        url("../../fonts/CabinetGrotesk-Regular.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
